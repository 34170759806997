import React, { useEffect } from "react";
import CareersSection6 from "../components/careers/CareersSection6";
import CareersSection7 from "../components/careers/CareersSection7";
import { SchemaLinksToAbsolute } from "../helpers/SchemaLinksToAbsolute";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import "../sass/pages/_careers-archive.scss";

const Careers = ({ data: { jobs, careerData } }) => {
  const content = careerData.acf_careers;

  useEffect(() => {
    if (typeof document !== "undefined") {
      document.querySelector("body").setAttribute("data-bg-color", "bg-white");
      document.querySelector("body").setAttribute("data-class", "careers-archive");
    }
  }, []);

  return (
    <>
      <Seo
        title={careerData.seo.title}
        description={careerData.seo.metaDesc}
        date={careerData.date}
        slug={"/" + careerData.slug + "/"}
        robotsFollow={careerData.seo.metaRobotsNofollow}
        robotsIndex={careerData.seo.metaRobotsNoindex}
        canonicalUrl={careerData.seo.canonical}
      />
      {careerData.seo.schema.raw !== undefined ? (
        <SchemaLinksToAbsolute schemaRaw={careerData.seo.schema.raw} />
      ) : (
        ""
      )}
      <div className="careers-archive post-type-archive-careers">
        <CareersSection6
          content={content}
          jobs={jobs}
          hasJobs={jobs.nodes.length > 0 ? true : false}
        />
        <CareersSection7 content={content} hasJobs={jobs.nodes.length > 0 ? true : false} />
      </div>
    </>
  );
};

export const query = graphql`
  {
    jobs: allWpCareer {
      nodes {
        title
        excerpt
        link
        acf_jobs {
          salary
          applicationDeadline
          subjectToExperienceToggle
        }
        careerCats {
          nodes {
            slug
          }
        }
      }
    }
    careerData: wpPage(slug: { eq: "careers" }) {
      id
      title
      date
      slug
      seo {
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        canonical
        schema {
          raw
        }
      }
      acf_careers {
        introSections {
          heading
          image1 {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image2 {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image3 {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          text
        }
        scrollingSections {
          colour
          heading
          text
        }
        section2Sections {
          heading
          points {
            heading
            icon {
              altText
              localFile {
                publicURL
              }
            }
            text
          }
        }
        section3Heading
        section3Image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        section3Text
        section5Heading
        section5Text
        section6Heading
        section7Heading
        section7Text
        section7TextAlternate
        stickyHeading
        stickyText
        section5Image {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        stickyImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default Careers;
